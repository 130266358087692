const mediasoupClient = require('mediasoup-client');
const { io } = require('socket.io-client');

class Main {
    constructor() {
        /**
         * Web socket model.
         */
        this.socket = null;
        /**
         * WebRTC producer transport.
         */
        this.producerTransport = [];
        /**
         * WebRTC device.
         */
        this.device = null;
        this.producerId = null;
        /**
         * Producers.
         */
        this.producers = new Map();
        /**
         * Map that contains a mediatype as key and producer_id as value
         */
        this.producerLabel = new Map();
        /**
         * Event Listeners.
         */
        this.eventListeners = new Map();

        this.engineTransport = {};

        this.mode = null;

        ['started', 'opened', 'closed', 'error'].forEach(
            function (evt) {
                this.eventListeners.set(evt, []);
            }.bind(this)
        );
    }

    /**
     * Connect to the server.
     */
    async connect(wss, options = {}) {
        if (options['videoElement']) {
            this.localVideoEl = options['videoElement'];
        }
        this.mediaConstraints = {};
        if (options['mediaConstraints']) {
            this.mediaConstraints = options['mediaConstraints'];
        }
        return new Promise(async (reslove, reject) => {
            options.transports = ['websocket'];
            this.socket = io(wss, {
                ...options,
                query: {
                    recordOnly: false,
                },
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 5,
            });

            /**
             * A sample request function.
             */
            this.socket.request = async (type, data = {}) => {
                return new Promise((resolve, reject) => {
                    this.socket.emit(type, data, (res) => {
                        if (res && res.error) {
                            reject(res.error);
                        } else {
                            resolve(res);
                        }
                    });
                });
            };

            this.socket.on('connect', async () => {
                reslove(this.socket);
            });

            this.socket.on('connect_error', (error) => {
                reject(error);
                this.socket.disconnect();
            });

            this.socket.on('error', (error) => {
                reject(error);
                this.socket.disconnect();
            });

            window.onbeforeunload = () => {
                if (this.socket) {
                    this.socket.disconnect();
                }
            };
        });
    }

    /**
     * Starts the call.
     */
    async createSession() {
        return new Promise((resolve, reject) => {
            this.socket
                .request('createSession')
                .then(async (sessionId) => {
                    try {
                        const data = await this.socket.request(
                            'getRouterRtpCapabilities'
                        );
                        const device = await this.loadDevice(
                            data.rtpCapabilities
                        );
                        this.device = device;
                        await this.getUserMedia(data.mediaConstraints);
                        this.event('opened');
                        await this.createAndProducer();
                        this.initSockets();
                        await this.socket.request('start');
                        resolve(sessionId);
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(reject);
        });
    }

    /**
     * Create Transport and produce it.
     */
    async createAndProducer() {
        const transport = await this.createTransports();
        await this.produce(transport);
        const transportSecond = await this.createTransports(true);
        await this.produce(transportSecond);
    }

    /**
     * Loads the device.
     * @param {Object} routerRtpCapabilities
     */
    async loadDevice(routerRtpCapabilities) {
        let device;
        const userAgent = window.navigator.userAgent;

        const deviceOptions = {};
       
        if (userAgent.includes('IONBanking-WBC')) {
            if (userAgent.includes('iPhone')) {
                deviceOptions.handlerName = 'Safari12';
            } else {
                deviceOptions.handlerName = 'Chrome74';
            }
        }else if(userAgent.includes('like Mac OS X') || userAgent.includes('AppleWebKit') || userAgent.includes('(KHTML, like Gecko)')){
            deviceOptions.handlerName = 'Safari12';
        }

        try {
            device = new mediasoupClient.Device(deviceOptions);
        } catch (error) {
            throw new Error(error);
        }

        await device.load({
            routerRtpCapabilities,
        });

        return device;
    }

    /**
     * Init sockets events.
     * @return {void}
     */
    initSockets() {}

    /**
     * Initia transports.
     */
    async createTransports(isSecond) {
        const producerData = await this.socket.request(
            'createWebRtcTransport',
            {
                forceTcp: false,
                rtpCapabilities: this.device.rtpCapabilities,
                isSecond,
            }
        );

        if (producerData.error) {
            this.handleError(producerData.error);
            throw new Error(producerData.error);
        }

        const producerTransport = this.device.createSendTransport(producerData);

        producerTransport.on(
            'connect',
            async ({ dtlsParameters }, callback, errback) => {
                this.socket
                    .request('connectTransport', {
                        dtlsParameters,
                        transport_id: producerData.id,
                        isSecond,
                    })
                    .then(callback)
                    .catch(errback);
            }
        );

        producerTransport.on(
            'produce',
            async ({ kind, rtpParameters }, callback, errback) => {
                try {
                    const { producer_id } = await this.socket.request(
                        'produce',
                        {
                            producerTransportId: producerTransport.id,
                            kind,
                            rtpParameters,
                            isSecond,
                        }
                    );

                    callback({
                        id: producer_id,
                    });
                } catch (err) {
                    errback(err);
                }
            }
        );

        producerTransport.on('connectionstatechange', (state) => {
            if (state === 'failed') {
                producerTransport.close();
            }
        });

        this.producerTransport.push(producerTransport);

        return producerTransport;
    }

    /**
     * Init Producer Transport.
     * @return {void}
     */
    async initProducerTransport(data) {
        return data;
    }

    /**
     * Create a producer.
     * @param {String} type
     */
    async produce(transport, isSecond) {
        if (!this.device.canProduce('video')) {
            const error = 'Cannot produce video.';
            this.handleError(error);
            throw new Error(error);
        }

        try {
            const videoTrack = this.stream.getVideoTracks()[0];

            const videoProducer = await transport.produce({
                track: videoTrack,
            });

            this.producers.set(videoProducer.id, videoProducer);

            this.producerEvent(videoProducer);
        } catch (err) {
            this.handleError(err);
            throw new Error(err);
        }
    }

    /**
     *
     * @param {*} producer
     */
    producerEvent(producer) {
        producer.on('trackended', () => {
            this.closeProducer(producer);
        });

        producer.on('transportclose', () => {
            this.producers.delete(producer.id);
        });

        producer.on('close', () => {
            this.producers.delete(producer.id);
        });
    }

    /**
     * Get or create user media.
     */
    async getUserMedia(constraints) {
        this.stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                ...this.mediaConstraints.video,
                ...constraints.video,
            },
        });

        if (this.localVideoEl.paused) {
            this.localVideoEl.srcObject = this.stream;
        }

        return this.stream;
    }

    /**
     * Close the producer.
     * @param {Object} producer
     */
    closeProducer(producer) {
        this.socket.emit('producerClosed', {
            producerId: producer.id,
        });
        this.producers.get(producer.id).close();
        this.producers.delete(producer.id);
    }

    /**
     * Exits the call.
     * @param {Boolean} successed
     */
    async close(successed) {
        return new Promise(async (resovle, reject) => {
            this._isOpen = false;
            if (this.producerTransport) {
                for (const transport of this.producerTransport) {
                    transport.close();
                }
            }

            try {
                await this.socket.request('complete', null);
                resovle();
                if (this.socket) {
                    this.socket.off('disconnect');
                    this.socket.disconnect();
                }

                this.event('closed');
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    }

    /**
     * Creates an event.
     * @param {String} eventName
     */
    event(eventName, any = {}) {
        if (this.eventListeners.has(eventName)) {
            this.eventListeners
                .get(eventName)
                .forEach((callback) => callback(any));
        }
    }

    /**
     * The listener.
     * @param {Strion} eventName
     * @param {Object} callback
     */
    on(eventName, callback) {
        if (callback) {
            this.eventListeners.get(eventName).push(callback);
        }
    }

    /**
     * Handles errors.
     */
    handleError(error) {
        console.error(error);
        this.event('error', error);
    }
}

module.exports = Main;
