const { EventEmitter } = require('events');
const Main = require('./main');

class Client {
    constructor() {
        this.main = new Main();

        this.socket = undefined;
        this.observer = new EventEmitter();

        this.results = {};
        this.detecting = {
            face: false,
            smile: false,
            head_left: false,
            head_right: false,
        };
        this.mode = null;
        this.options = {};
    }

    /**
     * Create a session.
     * @param {String} wss
     */
    async createSession(wss, options = {}) {
        this.options = options;
        return new Promise((resolve, reject) => {
            this.main
                .connect(wss, options)
                .then(async (ws) => {
                    this.socket = ws;
                    this.main
                        .createSession()
                        .then((sessionId) => {
                            resolve(sessionId);
                        })
                        .catch(reject);

                    this.socket.on('detect', (results) => {
                        this.results = this.formatResults(results);
                    });

                    this.main.on('error', (error) => this.observer.emit('error', error));
                    this.socket.on('start', () => {
                        this.observer.emit('opened', true);
                    });
                    this.socket.on('issue', (issue) => this.observer.emit('error', issue));
                })
                .catch((e) => {
                    if (e.message === 'Authentication') {
                        return reject({
                            code: 'E01',
                            message: 'Authentication',
                        });
                    }
                    reject(e);
                });
        });
    }

    /**
     * Detect emotions.
     * @param {String} type
     * @param {Number} timeout
     */
    async process(type, timeout = 8000) {
        this.detecting[type] = true;
        this.socket.emit('setDetect', true);
        return new Promise((resovle) => {
            const inter = setInterval(async () => {
                if (this.results[type] === '1') {
                    this.detecting[type] = false;
                    resovle(true);
                    this.socket.emit('setDetect', false);
                    clearInterval(inter);
                }
            });
            setTimeout(() => {
                if (this.detecting[type]) {
                    this.socket.emit('setDetect', false);
                    this.detecting[type] = false;
                    clearInterval(inter);
                    resovle(false);
                }
            }, timeout);
        });
    }

    /**
     *
     * @param {String} type
     */
    async detect(type, options = {}, redo = false) {
        return new Promise((resolve, reject) => {
            this.process(type, options.timeout || 8000).then(async (status) => {
                if (status) {
                    resolve();
                } else {
                    if (!redo && options.retry) {
                        if (options['onRepeat']) options.onRepeat();
                        return this.detect(type, options, true).then(resolve).catch(reject);
                    }

                    reject();
                }
            });
        });
    }

    /**
     * Close the session.
     */
    async close() {
        return new Promise((resolve, reject) => {
            this.main
                .close(false)
                .then(() => resolve())
                .catch((e) => reject(e));
        });
    }

    /**
     * Format results.
     * @param {String} results
     */
    formatResults(results) {
        return {
            face: results[0],
            smile: results[1],
            head_right: results[2],
            head_left: results[3],
        };
    }
}

module.exports = Client;
